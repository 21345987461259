import "./About.css"

const textArray:Array<string> = [

    'I am a software developer with 5 years of experience. ' + 
    'My professional experience is primarily in the C#/.Net windows environment, ' +
    'with some light exposure unix systems through to ksh and AIX. ' +
    'I am proficient in Javascript and I am currently learning React in ' +
    'side projects, as displayed here on this website. '
    ,
    'My current hobbies include running, chess, painting, hiking, and a light ' +
    'amount of competitive Total War: Warhammar 3.'
]
export default function About()
{
    return <><div className="text-card">
        <div className="content">
            <p className="title">About Me</p>
            {
                textArray.map((x, index1)=>(
                    <p key={index1} className="text-content">
                    {
                        x.split(' ').map((y, index2) =>(
                            <span key={index2} className="word-span">
                                {y + " "}
                            </span>
                        ))
                    }
                    </p>
                ))
            }
           
            </div>
        </div></>
}