import ProjectCards from "./ProjectsCards";

export default function Projects()
{
    return (
    
        <>
            <ProjectCards />
        </>
    )
}