import ProjectCards from "./ProjectsCards"
export default function  Home() {
  return (
    <>
    <div id='Home'>
      <h1>Welcome to my website</h1>
      <ProjectCards />
    </div>
    
    </>
  )
}